import Button from "../../shared/Button/Button";
import styles from "./Home.module.scss";
import { services } from "../../utils/consts";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div className={styles.home}>
            <div className={styles.description}>
                <img src="/images/homeBackground.png" />
                <div className={styles.content}>
                    <span className={styles.title}>Клиника здоровья и красоты</span>
                    <div className={styles.virtues}>
                        <div>
                            <span className={styles.symbol}>✦</span>
                            <span>Профессионалы высочайшего уровня</span>
                        </div>
                        <div>
                            <span className={styles.symbol}>✦</span>
                            <span>Современное оборудование</span>
                        </div>
                        <div>
                            <span className={styles.symbol}>✦</span>
                            <span>Широкий спектр услуг</span>
                        </div>
                        <div>
                            <span className={styles.symbol}>✦</span>
                            <span>Работаем с 2007 года</span>
                        </div>
                    </div>
                </div>
                <span className={styles.slogan}>Ваше здоровье — наш приоритет</span>
                <div className={styles.subdescription}>
                    <span>
                        У нас в наличии имеется самое современное оборудование, благодаря которому все процедуры
                        проводятся качественно и с максимальным оздоровительным эффектом.
                    </span>
                </div>
            </div>
            <div className={styles.services}>
                <span className={styles.title}>Наши услуги</span>
                <Link to="/services">
                    <Button title="все услуги" />
                </Link>

                <div className={styles.content}>
                    {services.slice(0, 6).map((service, index) => (
                        <Link key={index} to={`/services/${service.id}`} className={styles.contentItem}>
                            <img src={service.img} alt={service.name} />
                            <span>{service.name}</span>
                        </Link>
                    ))}
                </div>
            </div>
            {/* <div className={styles.prices}>
                <div>
                    <span className={styles.title}>Цены на популярные услуги</span>
                </div>

                <Table columns={[]} data={prices} />
            </div> */}
            {/* <div className={styles.wrapper}>
                <div>
                    <span className={styles.title}>До и после </span>
                </div>
                <Carousel list={images} />
            </div> */}
            <div className={styles.clinics}>
                <div>
                    <span className={styles.title}>Наши клиники</span>
                </div>
                <div className={styles.clinicsList}>
                    <div>
                        <span className={styles.title}>КОРПУС НА УЛ. КИРОВА</span>
                        <span>+7 (8412) 45-68-68</span>
                        <span>г. Пенза ул. Кирова, 15В</span>
                    </div>
                    <div>
                        <span className={styles.title}>ГРАФИК РАБОТЫ</span>
                        <span>с 09:00 до 20:00</span>
                        <span>ПН-СБ</span>
                    </div>
                    <div>
                        <img src="/images/clinic1.png" />
                    </div>
                    <div>
                        <span className={styles.title}>КОРПУС НА УЛ. ТЕРНОПОЛЬСКАЯ</span>
                        <span>+7 (8412) 68-68-88</span>
                        <span>г. Пенза ул. Тернопольская, 1</span>
                    </div>
                    <div>
                        <span className={styles.title}>ГРАФИК РАБОТЫ</span>
                        <span>с 09:00 до 20:00</span>
                        <span>ПН-СБ</span>
                    </div>
                    <div>
                        <img src="/images/clinic2.png" />
                    </div>
                    <div>
                        <span className={styles.title}>КОРПУС НА УЛ. СТАВСКОГО</span>
                        <span>+7 (8412) 68-68-88</span>
                        <span>г. Пенза ул. Ставского, 29</span>
                    </div>
                    <div>
                        <span className={styles.title}>ГРАФИК РАБОТЫ</span>
                        <span>с 09:00 до 20:00</span>
                        <span>ПН-СБ</span>
                    </div>
                    <div>
                        <img src="/images/clinic3.png" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
