import { Link } from "react-router-dom";
import styles from "./Services.module.scss";
import { services } from "../../utils/consts";
import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";

const Services = () => {
    const breadcrumbsList = [
        { name: "Главная", to: "/" },
        { name: "Услуги", to: "/services" },
    ];
    
    return (
        <div className={styles.services}>
            <Breadcrumbs links={breadcrumbsList} />
            <span className={styles.title}>Наши услуги</span>
            <div className={styles.content}>
                {services.map((service, index) => (
                    <div key={index} className={styles.contentItem}>
                        <div className={styles.imgWrapper}>
                            <img src={service.img} alt="service" />
                        </div>
                        <div className={styles.description}>
                            <span className={styles.title}>{service.name}</span>
                            <Link to={`/services/${service.id}`} className={styles.more}>
                                подробнее
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;
