import styles from "./Employee.module.scss";
import { useParams } from "react-router-dom";
import { employees } from "../../utils/consts";
import { useEffect, useState } from "react";
import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";

const Employee = () => {
    const { id } = useParams();
    const currentEmployee = id ? employees.find((employee) => employee.id == Number(id)) : null;

    const [breadcrumbsList, setBreadcrumbsList] = useState<any[]>([]);

    useEffect(() => {
        if (currentEmployee) {
            setBreadcrumbsList([
                { name: "Главная", to: "/" },
                { name: "Персонал", to: "/employees" },
                { name: currentEmployee.name, to: `/employees/${currentEmployee.id}` },
            ]);
        }
    }, [currentEmployee]);

    return (
        <div className={styles.employee}>
            <Breadcrumbs links={breadcrumbsList} />
            <div className={styles.profile}>
                <img src={currentEmployee?.img} width={500} height={600} />
                <div className={styles.employeeInfo}>
                    <span className={styles.name}>{currentEmployee?.name}</span>
                    {currentEmployee?.positions.map((position) => (
                        <span className={styles.position}>{position}</span>
                    ))}
                    {currentEmployee?.description && (
                        <span className={styles.description}>{currentEmployee?.description}</span>
                    )}
                    {/* <span className={styles.shedule}>
                        <span>График работы:</span>
                    </span> */}
                </div>
            </div>
            {currentEmployee && currentEmployee.education.length > 0 && (
                <div className={styles.education}>
                    <span className={styles.title}>ОБРАЗОВАНИЕ</span>
                    <ul className={styles.educationList}>
                        {currentEmployee?.education?.map((ed) => (
                            <li>{ed}</li>
                        ))}
                    </ul>
                </div>
            )}

            {/* <div className={styles.proceduresList}>
                <span className={styles.title}>СПИСОК ВЫПОЛНЯЕМЫХ ПРОЦЕДУР</span>
            </div> */}
        </div>
    );
};

export default Employee;
