import styles from "./Header.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { navList } from "./consts";
import { useState } from "react";
import classNames from "classnames";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const handleChangeLocation = (newLocation: string) => {
        navigate(newLocation);
        setIsOpen(false);
    };

    return (
        <div className={styles.header}>
            <div>
                <Link to="/">
                    <img src="/images/icons/logo.svg" alt="logo" />
                </Link>
            </div>
            <ul className={classNames(styles.navList, { [styles.isOpen]: isOpen })}>
                {navList.map((navListItem, index) => (
                    <li
                        key={index}
                        className={styles.navListItem}
                        onClick={() => handleChangeLocation(navListItem.href)}
                    >
                        {navListItem.name}
                    </li>
                ))}
            </ul>
            <div className={styles.contactUs}>
                <img src="/images/icons/whatsapp.svg" width={48} height={48} alt="whatsapp" />
                <img src="/images/icons/telegram.svg" width={48} height={48} alt="telegram" />
            </div>
            <img src="/images/icons/burgerMenu.svg" onClick={() => setIsOpen(!isOpen)} />
        </div>
    );
};

export default Header;
