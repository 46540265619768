import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";
import classNames from "classnames";

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.grid}>
                <img className={styles.logo} src="/images/icons/logo.svg" />
                <div className={classNames(styles.column, styles.clinic)}>
                    <span className={styles.title}>КЛИНИКА</span>
                    <ul>
                        <li>
                            <Link to="/services">Список услуг</Link>
                        </li>
                        <li>
                            <Link to="/employees">Сотрудники</Link>
                        </li>
                        <li>
                            <Link to="/certificates">Сертификаты</Link>
                        </li>
                        <li>
                            <Link to="/about">О нас</Link>
                        </li>
                    </ul>
                </div>
                <div className={classNames(styles.column, styles.contacts)}>
                    <span className={styles.title}>КОНТАКТЫ</span>
                    <div>
                        <span>+7 (8412) 45-68-68</span>
                        <span>г. Пенза ул. Кирова, 15В</span>
                    </div>
                    <div>
                        <span>+7 (8412) 98-68-68</span>
                        <span>г. Пенза ул. Тернопольская, 1</span>
                    </div>
                    <div>
                        <span>+7 (8412) 68-68-88</span>
                        <span>г. Пенза ул. Ставского, 29</span>
                    </div>
                </div>
                <span className={styles.company}>2023 © КОСМОМЕД — Врачебная косметология</span>
                <div className={styles.socialNetworks}>
                    <img src="/images/icons/whatsapp.svg" width={49} height={49} alt="whatsapp" />
                    <img src="/images/icons/telegram.svg" width={49} height={49} alt="telegram" />
                </div>
            </div>
        </div>
    );
};

export default Footer;
