import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";
import Button from "../../shared/Button/Button";
import styles from "./Certificates.module.scss";

const Certificates = () => {
    const breadcrumbsList = [
        { name: "Главная", to: "/" },
        { name: "Сертификаты", to: "/certificates" },
    ];

    return (
        <div className={styles.certificates}>
            <Breadcrumbs links={breadcrumbsList} />
            <span className={styles.title}>Подарочные сертификаты</span>
            <span className={styles.subtitle}>
                У нас в наличии имеется самое современное оборудование, благодаря которому все процедуры проводятся
                качественно и с максимальным оздоровительным эффектом.
            </span>
            {/* <span className={styles.subtitle}>Доступны разные номиналы сертификатов:</span>
            <div className={styles.denominations}>
                <span>-1000 руб</span>
                <span>-1000 руб</span>
                <span>-1000 руб</span>
                <span>-1000 руб</span>
                <span>-1000 руб</span>
                <span>-1000 руб</span>
            </div> */}
            <div className={styles.certificatesList}>
                <img src="/images/certificate1.png" />
                <img src="/images/certificate2.png" />
            </div>
            {/* <Button title="заказать" /> */}
        </div>
    );
};

export default Certificates;
