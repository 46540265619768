import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";
import Employee from "../../shared/Employee/Employee";
import { employees } from "../../utils/consts";
import styles from "./Employees.module.scss";

const Employees = () => {
    const breadcrumbsList = [
        { name: "Главная", to: "/" },
        { name: "Персонал", to: "/employees" },
    ];

    return (
        <div className={styles.employees}>
            <Breadcrumbs links={breadcrumbsList} />
            <span className={styles.title}>Наши сотрудники</span>
            <div className={styles.content}>
                {employees.map((employee) => (
                    <Employee key={employee.id} employee={employee} />
                ))}
            </div>
        </div>
    );
};

export default Employees;
