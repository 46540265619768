import styles from "./Button.module.scss"
import { ButtonHTMLAttributes } from "react";

type TButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    title: string;
};

const Button = ({ title }: TButtonProps) => {
    return <button className={styles.button}>{title}</button>;
};

export default Button;
