import styles from "./Service.module.scss";
import Table from "../../shared/Table/Table";
import { employees } from "./consts";
import { useParams } from "react-router-dom";
import { services } from "../../utils/consts";
import Employee from "../../shared/Employee/Employee";
import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";
import { useEffect, useState } from "react";

const Service = () => {
    const { id } = useParams();
    const currentService = id ? services.find((service) => service.id == Number(id)) : null;

    const [breadcrumbsList, setBreadcrumbsList] = useState<any[]>([]);

    useEffect(() => {
        if (currentService) {
            setBreadcrumbsList([
                { name: "Главная", to: "/" },
                { name: "Услуги", to: "/services" },
                { name: currentService.name, to: `/services/${currentService.id}` },
            ]);
        }
    }, [currentService]);

    return (
        <div className={styles.service}>
            <Breadcrumbs links={breadcrumbsList} />
            <div className={styles.services}>
                <span className={styles.title}>Наши услуги</span>
                <div>
                    <img src={currentService?.img} />
                    <div className={styles.serviceInfo}>
                        <span className={styles.name}>{currentService?.name}</span>
                        <span className={styles.description}>{currentService?.description}</span>
                    </div>
                </div>
                {currentService?.list.length > 0 && (
                    <Table
                        columns={["Наименование услуги", "Кирова", "Тернопольская", "Ставского"]}
                        data={currentService?.list}
                    />
                )}
            </div>
            {/* <div className={styles.executors}>
                <span className={styles.title}>Данную процедуру оказывают</span>
                <div>
                    {employees.map((employee, index) => (
                        <Employee key={index} employee={employee} />
                    ))}
                </div>
            </div> */}
        </div>
    );
};

export default Service;
