import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import Service from "./pages/Service/Service";
import Employees from "./pages/Employees/Employees";
import Employee from "./pages/Employee/Employee";
import Certificates from "./pages/Certificates/Certificates";
import About from "./pages/About/About";
import Contacts from "./pages/Contacts/Contacts";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./shared/ScrollToTop/ScrollToTop";
import styles from "./App.module.scss";

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="*" element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/:id" element={<Service />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/employees/:id" element={<Employee />} />
                <Route path="/certificates" element={<Certificates />} />
                <Route path="/about" element={<About />} />
                <Route path="/contacts" element={<Contacts />} />
            </Routes>
            <a
                className={styles.appointment}
                href="https://widget.archimed-soft.ru/widget/0e36555b1199a8756659e4cc468da124"
            >
                Запись на прием
            </a>
            <Footer />
        </Router>
    );
}

export default App;
