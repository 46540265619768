import { Link } from "react-router-dom";
import styles from "./Employee.module.scss";

type TEmployee = {
    id: number;
    img: string;
    name: string;
    positions: string[];
};

type TEmployeeProps = {
    employee: TEmployee;
};

const Employee = ({ employee }: TEmployeeProps) => {
    return (
        <Link key={employee.id} to={`/employees/${employee.id}`} className={styles.employee}>
            <img src={employee.img} alt={`employee-${employee.id}`} />
            <span className={styles.name}>{employee.name}</span>
            <span className={styles.position}>{employee.positions[0]}</span>
        </Link>
    );
};

export default Employee;
