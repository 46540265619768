import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import styles from "./Contacts.module.scss";
import { useState } from "react";
import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";
import classNames from "classnames";

const Contacts = () => {
    const breadcrumbsList = [
        { name: "Главная", to: "/" },
        { name: "Контакты", to: "/contacts" },
    ];
    const [geometry, setGeometry] = useState([53.184246, 45.017085]);
    const [currenClinic, setCurrentClinic] = useState(0);

    return (
        <div className={styles.contacts}>
            <Breadcrumbs links={breadcrumbsList} />
            <div className={styles.clinics}>
                <div>
                    <span className={styles.title}>Наши клиники</span>
                </div>
                <div className={styles.clinicsList}>
                    <div>
                        <span className={styles.title}>КОРПУС НА УЛ. КИРОВА</span>
                        <span className={styles.text}>+7 (8412) 45-68-68</span>
                        <span className={styles.text}>г. Пенза ул. Кирова, 15В</span>
                    </div>
                    <div>
                        <span className={styles.title}>ГРАФИК РАБОТЫ</span>
                        <span className={styles.text}>с 09:00 до 20:00</span>
                        <span className={styles.text}>ПН-СБ</span>
                    </div>
                    <div>
                        <img src="/images/clinic1.png" />
                    </div>
                    <div>
                        <span className={styles.title}>КОРПУС НА УЛ. ТЕРНОПОЛЬСКАЯ</span>
                        <span className={styles.text}>+7 (8412) 98-68-68</span>
                        <span className={styles.text}>г. Пенза ул. Тернопольская, 1</span>
                    </div>
                    <div>
                        <span className={styles.title}>ГРАФИК РАБОТЫ</span>
                        <span className={styles.text}>с 09:00 до 20:00</span>
                        <span className={styles.text}>ПН-СБ</span>
                    </div>
                    <div>
                        <img src="/images/clinic2.png" />
                    </div>
                    <div>
                        <span className={styles.title}>КОРПУС НА УЛ. СТАВСКОГО</span>
                        <span className={styles.text}>+7 (8412) 68-68-88</span>
                        <span className={styles.text}>г. Пенза ул. Ставского, 29</span>
                    </div>
                    <div>
                        <span className={styles.title}>ГРАФИК РАБОТЫ</span>
                        <span className={styles.text}>с 09:00 до 20:00</span>
                        <span className={styles.text}>ПН-СБ</span>
                    </div>
                    <div>
                        <img src="/images/clinic3.png" />
                    </div>
                </div>
            </div>
            <div className={styles.contactUs}>
                <div>
                    <div>
                        <span className={styles.title}>Свяжитесь с нами онлайн прямо сейчас</span>
                        <span className={styles.text}>
                            Вы можете использовать наш Whatsup или Telegram по телефону или по ссылке из прямо с нашей
                            страницы
                        </span>
                    </div>
                    <div>
                        <img src="/images/icons/whatsapp.svg" width={100} height={100} alt="whatsapp" />
                        <img src="/images/icons/telegram.svg" width={100} height={100} alt="telegram" />
                    </div>
                </div>
            </div>
            <div className={styles.map}>
                <div className={styles.heading}>
                    <span className={styles.title}>Как нас найти</span>
                    <div>
                        <span
                            className={classNames(styles.text, { [styles.active]: currenClinic === 0 })}
                            onClick={() => {
                                setGeometry([53.184246, 45.017085]);
                                setCurrentClinic(0);
                            }}
                        >
                            КОРПУС НА ул. КИРОВА
                        </span>
                        <span
                            className={classNames(styles.text, { [styles.active]: currenClinic === 1 })}
                            onClick={() => {
                                setGeometry([53.22942, 44.926203]);
                                setCurrentClinic(1);
                            }}
                        >
                            КОРПУС НА ул. ТЕРНОПОЛЬСКАЯ
                        </span>
                        <span
                            className={classNames(styles.text, { [styles.active]: currenClinic === 2 })}
                            onClick={() => {
                                setGeometry([53.195937, 45.004078]);
                                setCurrentClinic(2);
                            }}
                        >
                            КОРПУС НА ул. СТАВСКОГО
                        </span>
                    </div>
                </div>
                <YMaps>
                    <div>
                        <Map state={{ center: geometry, zoom: 17 }}>
                            <Placemark
                                modules={["geoObject.addon.balloon"]}
                                geometry={geometry}
                                properties={{
                                    balloonContentBody: "This is balloon loaded by the Yandex.Maps API module system",
                                }}
                            />
                        </Map>
                    </div>
                </YMaps>
            </div>
        </div>
    );
};

export default Contacts;
