import styles from "./Breadcrumbs.module.scss";
import { Link } from "react-router-dom";

type TBreadcrumb = {
    name: string;
    to: string;
};

type TBreadcrumbsProps = {
    links: TBreadcrumb[];
};
const Breadcrumbs = ({ links }: TBreadcrumbsProps) => {
    return (
        <div className={styles.breadcrumbs}>
            {links.map((link, index) => (
                <Link key={index} to={link.to}>{link.name}</Link>
            ))}
        </div>
    );
};

export default Breadcrumbs;
